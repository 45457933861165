<template>
  <v-card>
    <v-toolbar style="background-color: #006368; color: #fff">
      <v-toolbar-title>
        <span class="headline">Medicação</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-3">
      <div class="container">
        <div class="row">
          <div class="col-6 text-left">
            <v-card class="px-5 py-2">
              <h5 class="text-center m-0">Animal</h5>
              <p class="m-0">
                Nome: {{ infoMedicamento.internacao.animal_nome }}<br />
                Classe: {{ infoMedicamento.internacao.classe }}<br />
                Classe: {{ infoMedicamento.internacao.familia }}<br />
                Espécie: {{ infoMedicamento.internacao.nome_cientifico }} |
                {{ infoMedicamento.internacao.nome_popular }}
              </p>
            </v-card>
            <v-simple-table class="my-3" height="250px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Remedio</th>
                    <th class="text-left">Dose</th>
                    <th class="text-left">Hora</th>
                    <th class="text-left">Status</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in infoMedicamento.compromisso"
                    :key="item.id_data_agenda"
                  >
                    <td>{{ item.medicamento }}</td>
                    <td>{{ item.dosagem }}</td>
                    <td>{{ formatHora(item.hora_agenda) }}</td>
                    <td :class="item.status">
                      {{ item.status }}
                    </td>
                    <td>
                      <v-btn
                        icon
                        x-small
                        title="Medicamento"
                        @click="aplicarMedicamento(item)"
                      >
                        <v-icon>mdi mdi-pill</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="col-6 text-left">
            <div v-if="medicamentoSelected">
              <v-card class="px-5 py-2">
                <h5 class="text-center m-0">Medicamento</h5>
                <p class="m-0">
                  Tipo: {{ medicamento.descricao_tipo }}<br />
                  Nome: {{ medicamento.medicamento }}<br />
                  Dose: {{ medicamento.dosagem }}<br />
                  Administração: {{ medicamento.administracao_medicamento
                  }}<br />
                  Hora: {{ formatHora(medicamento.hora_agenda) }}
                </p>
              </v-card>
              <div class="container-fluid my-3">
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      v-model="data_medicacao"
                      label="Data"
                      type="date"
                      hide-details
                      :disabled="medicamento.status == 'aplicado'"
                    />
                  </div>
                  <div class="col-6">
                    <v-text-field
                      v-model="hora_medicacao"
                      label="Hora"
                      hide-details
                      type="time"
                      :disabled="medicamento.status == 'aplicado'"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <v-textarea
                      v-model="observacao_medicacao"
                      label="Observação"
                      hide-details
                      rows="3"
                      :disabled="medicamento.status == 'aplicado'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between p-3">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        v-if="medicamentoSelected && medicamento.status != 'aplicado'"
        color="primary"
        :disabled="invalidForm()"
        @click="medicar()"
      >
        Medicar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import PrescricaoService from "@/services/prescricao_service.js";

export default {
  mixins: [Mixin],
  props: {
    infoMedicamento: Object,
  },
  components: {},
  data: () => ({
    services: {
      prescricaoService: PrescricaoService.build(),
    },
    medicamento: {},
    medicamentoSelected: false,
    data_medicacao: "",
    hora_medicacao: "",
    observacao_medicacao: "",
  }),
  watch: {},
  mounted() {
    // const hj = new Date();
    // const hora =
    //   hj.getHours().toString().padStart(2, "0") +
    //   ":" +
    //   hj.getMinutes().toString().padStart(2, "0");
    // this.data_medicacao = hj.toISOString().split("T")[0];
    // this.hora_medicacao = hora;
  },
  computed: {},
  methods: {
    medicar() {
      this.services.prescricaoService
        .postExecutarPrescricao(this.getModel())
        .then(async (response) => {
          if (response.status == 200 || response.status == 206) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Medicação executada com sucesso!",
              color: "sucess",
            });
            this.$emit("close");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao executar medicação",
              color: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    // getModel() {
    //   return {
    //     id_agenda: this.medicamento.id_data_agenda,
    //     hora_executada: this.data_medicacao,
    //     data_executada: this.hora_medicacao + ":00",
    //     obs_executada: this.observacao_medicacao,
    //   };
    // },
    getModel() {
      return {
        id_data_agenda: this.medicamento.id_data_agenda,
        data_execucao: this.data_medicacao,
        hora_execucao: this.hora_medicacao + ":00",
        obs: this.observacao_medicacao,
      };
    },
    dataHoraInput() {
      const hj = new Date();
      const hora =
        hj.getHours().toString().padStart(2, "0") +
        ":" +
        hj.getMinutes().toString().padStart(2, "0");
      this.data_medicacao = hj.toISOString().split("T")[0];
      this.hora_medicacao = hora;
    },
    aplicarMedicamento(item) {
      this.clearForm();
      if (item.status == "aplicado") {
        this.data_medicacao = item.data_executada;
        let arrHora = item.hora_executada.split(":");
        this.hora_medicacao = `${arrHora[0]}:${arrHora[1]}`;
        this.observacao_medicacao = item.obs_executada;
      } else {
        this.dataHoraInput();
      }
      this.medicamento = item;
      this.medicamentoSelected = true;
    },
    clearForm() {
      this.data_medicacao = "";
      this.hora_medicacao = "";
      this.observacao_medicacao = "";
    },
    invalidForm() {
      if (
        this.data_medicacao == "" ||
        this.hora_medicacao == ""
        // this.observacao_medicacao == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    formatHora(hora) {
      const formatedHora = hora.split(":");
      return `${formatedHora[0]}:${formatedHora[1]}`;
    },
  },
};
</script>
<style scoped>
.aplicado {
  color: green;
}
.pendente {
  color: red;
}
</style>
