<template>
  <v-card>
    <!-- <v-toolbar style="background-color: #006f91; color: #fff"> -->
    <v-toolbar style="background-color: #750800; color: #fff">
      <v-toolbar-title>
        <!-- <span class="headline">Gerar Documento</span> -->
        <span class="headline">Saída Internação</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- <h4>Seleciona o documento que deseja emitir?</h4> -->
            <h4>Seleciona o documento para emissão de saída da saída:</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-select
              v-model="documentoSelect"
              label="Documentos"
              outlined
              :items="listaDocumentos"
              item-text="desc"
              item-value="valor"
              hide-details
              :disabled="loading == true"
            ></v-select>
          </div>
        </div>
        <div v-if="documentoSelect == '4'">
          <div class="new-edit-2">
            <vue-editor
              v-model="content"
              :editorToolbar="toolbar"
              :disabled="loading == true"
            ></vue-editor>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-end pb-7 px-7">
      <!-- <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn> -->
      <!-- <v-btn
        color="#501777"
        @click="emitirSaida('hospital')"
        :dark="documentoSelect != '' && loading != true"
        :disabled="documentoSelect == '' || loading == true"
        :loading="loading == true"
      >
        Saída Internação e Hospital
      </v-btn> -->
      <v-btn
        color="#750800"
        @click="emitirSaida()"
        :dark="documentoSelect != '' && loading != true"
        :disabled="documentoSelect == '' || loading == true"
        :loading="loading == true"
      >
        {{ saidaDescription() }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import InternacaoService from "@/services/internacao_service.js";
import AnimalService from "@/services/animal_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DeclaracaoObito } from "@/components/documentos/modelos/declaracao_obito.js";
import { EmissaoAlta } from "@/components/documentos/modelos/emissao_alta.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { VueEditor } from "vue2-editor";

export default {
  mixins: [Mixin],
  props: {
    infoAnimal: Object,
  },
  components: { VueEditor },
  data: () => ({
    services: {
      internacaoService: InternacaoService.build(),
      animalService: AnimalService.build(),
    },
    listaDocumentos: [
      { valor: "4", desc: "Emissão de Alta Médica" },
      { valor: "5", desc: "Declaração de Óbito" },
      { valor: "6", desc: "Declaração de Óbito - Pesquisa" },
    ],
    documentoSelect: "",
    content: "",
    contentFormat: [],
    toolbar: [
      [],
      // [{ header: [1, 2, false] }],
      // ["bold", "italic", "underline"],
    ],
    animalInfo: {
      rgv: " -- ",
      nome: " -- ",
      classe: " -- ",
      especie: " -- ",
      sexo: " -- ",
      idade: " -- ",
    },
    orgaoInfo: {
      nome: " -- ",
      cnpj: " -- ",
      telefone: " -- ",
      nomeResp: " -- ",
      cpfResp: " -- ",
      logradouro: " -- ",
      numero: " -- ",
      cidade: " -- ",
      cep: " -- ",
    },
    unidadeInfo: {
      id: "-- id --",
      razao_social: "-- razao_social --",
      cnpj: "-- cnpj --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    loading: false,
    usuario: "",
    dataSaida: {
      data_saida: "--",
      hora_saida: "--",
    },
  }),
  watch: {
    documentoSelect() {
      if (this.documentoSelect == "4") {
        this.content = "";
      }
    },
  },
  mounted() {
    this.getInfoEntrada();
    const unidade = JSON.parse(sessionStorage.vuex).unidade;
    this.unidadeInfo = {
      id: unidade.id,
      razao_social: unidade.razao_social,
      cnpj: unidade.cnpj,
      logradouro: unidade.logradouro,
      numero: unidade.numero,
      cidade: unidade.cidade,
      cep: unidade.cep,
    };
    const usuario = JSON.parse(sessionStorage.vuex).usuario.dados;
    this.usuario = usuario.nome;
  },
  computed: {},
  methods: {
    getInfoEntrada() {
      this.services.animalService
        .getEntradaAnimalbyId(this.infoAnimal.id_entrada)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            const info = data;
            this.animalInfo = {
              rgv: info.pet_data.rgv,
              nome: info.pet_data.nome,
              classe: info.pet_data.descricao_classe,
              especie: `Popular: ${info.pet_data.nome_popular} | Científico ${info.pet_data.nome_cientifico}`,
              especie_popular: info.pet_data.nome_popular,
              especie_cientifico: info.pet_data.nome_cientifico,
              familia: info.pet_data.familia,
              // especie: `Científico: ${info.nome_cientifico} | Popular: ${info.nome_popular}`,
              // cientifico: info.nome_cientifico,
              // popular: info.nome_popular,
              sexo: info.pet_data.sexo,
              idade: info.pet_data.idade,
            };
            this.orgaoInfo = {
              nome: info.orgao_data.razao_social,
              cnpj: info.orgao_data.cnpj,
              telefone: info.orgao_data.telefone,
              nomeResp: info.responsavel_data.nome,
              cpfResp: info.responsavel_data.cpf,
              logradouro: info.responsavel_data.logradouro,
              numero: info.responsavel_data.numero,
              cidade: info.responsavel_data.cidade,
              cep: info.responsavel_data.cep,
              regcid: info.responsavel_data.regiao_cidade,
              ent_logradouro: info.entrada_data.logradouro,
              ent_numero: info.entrada_data.numero,
              ent_cidade: info.entrada_data.cidade,
              ent_cep: info.entrada_data.cep,
              ent_regcid: info.entrada_data.regiao_cidade,
            };
            // const model = {
            //   bairro: "Engenheiro Luciano Cavalcante",
            //   cep: "60810-350",
            //   cidade: "Fortaleza",
            //   cnpj: "76.924.668/0001-97",
            //   complemento: "referencia",
            //   data_entrada: "2024-07-12",
            //   data_saida: "1900-01-01",
            //   descricao: "Ave",
            //   estado: "Ceará",
            //   estado_animal: "Ferido",
            //   hora_entrada: "16:28:30",
            //   hora_saida: "00:00:00",
            //   id: "4",
            //   id_classe: "1",
            //   id_endereco: "6",
            //   id_especie: "1",
            //   id_pessoa_juridica: "5",
            //   id_pet: "53",
            //   idade_animal: "filhote",
            //   logradouro: "Avenida Washington Soares",
            //   matricula_servidor: "4588568589",
            //   nome: "Kankinho 10",
            //   nome_cientifico: "testupdate especie",
            //   nome_fantasia: "orgao teste",
            //   nome_popular: "tetste nome_popular",
            //   numero: "71",
            //   referencias: "referencias",
            //   responsavel: "Teste",
            //   rgv: "754486906",
            //   tipo_entrada: "Apreensão",
            // };
          }
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {});
    },
    // emitirSaida(tipo = "internacao") {
    emitirSaida() {
      this.loading = true;
      // this.services.entradaService
      //   .statusEntrada(this.infoAnimal.id, this.documentoSelect)
      this.services.internacaoService
        .putSaidaInternacao(this.getModel(this.infoAnimal.id))
        .then(async (response) => {
          if (response.status == 200) {
            // if (tipo == "hospital") {
            //   this.saidaHospital();
            // }
            const body = await response.json();
            this.dataSaida = {
              data_saida: body.data.data_saida,
              hora_saida: body.data.hora_saida,
            };
            this.gerarDoc();
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.err(err);
          this.loading = false;
        })
        .finally(() => {});
    },
    // saidaHospital() {
    //   this.services.entradaService
    //     // .putSaidaHospital(this.infoAnimal.id_entrada,this.getModelHospital(this.infoAnimal.id_entrada))
    //     .putSaidaHospital(this.getModelHospital(this.infoAnimal.id_entrada))
    //     .then(async (response) => {
    //       if (response.status == 200) {
    //         const body = await response.json();
    //         console.log("b", body);
    //       }
    //     })
    //     .catch((err) => {
    //       console.err(err);
    //     })
    //     .finally(() => {});
    // },
    getModel(id) {
      let tipoSaida = 4;
      switch (this.documentoSelect) {
        // alta medica
        case "4":
          tipoSaida = 1;
          break;
        // declaracao
        case "5":
          tipoSaida = 2;
          break;
        // declaracao - pesquisa
        case "6":
          tipoSaida = 3;
          break;
      }
      return {
        id: parseInt(id),
        tipo_saida: tipoSaida,
      };
    },
    gerarDoc() {
      let docArquivo = "";
      let descDoc = "";
      let internacao = { valida: true, ...this.infoAnimal };
      if (this.documentoSelect == "5") {
        docArquivo = DeclaracaoObito(
          this.animalInfo,
          this.orgaoInfo,
          this.unidadeInfo,
          this.dataSaida,
          this.usuario,
          internacao
        );
        descDoc = "Declaração de Óbito.pdf";
      }
      if (this.documentoSelect == "6") {
        docArquivo = DeclaracaoObito(
          this.animalInfo,
          this.orgaoInfo,
          this.unidadeInfo,
          this.dataSaida,
          this.usuario,
          internacao
        );
        descDoc = "Declaração de Óbito - Pesquisa.pdf";
      }
      if (this.documentoSelect == "4") {
        this.formatEdit();
        docArquivo = EmissaoAlta(
          this.animalInfo,
          this.orgaoInfo,
          this.contentFormat,
          this.unidadeInfo,
          this.dataSaida,
          this.usuario,
          internacao
        );
        descDoc = "Emissão de Alta Médica.pdf";
      }
      pdfMake.createPdf(docArquivo).download(descDoc);
      this.loading = false;
      this.$emit("emitirSaida");
    },
    formatEdit() {
      let text = this.content
        .replaceAll("<p>", "")
        .replaceAll("<br>", "")
        .split("</p>");
      this.contentFormat = [];
      if (text.length > 0) {
        for (let index = 0; index < text.length; index++) {
          const element = text[index];
          if (index != text.length - 1 && element != "") {
            this.contentFormat.push({
              text: element,
              style: "text",
            });
          }
        }
      }
    },
    saidaDescription() {
      return this.documentoSelect == 4
        ? "Saída Internação"
        : "Saída Internação e Hospital";
    },
    // mapHtmlToArray() {
    //   const tempDiv = document.createElement("div");
    //   tempDiv.innerHTML = this.content;
    //   const elements = tempDiv.children;
    //   let mappedContent = "";

    //   const result = [];
    //   for (let i = 0; i < elements.length; i++) {
    //     const tag = elements[i].tagName.toLowerCase();
    //     const content = elements[i].innerHTML;
    //     result.push({ tag, content });
    //   }

    //   mappedContent = result;
    //   console.log(mappedContent);
    // },
  },
};
</script>
<style scoped></style>
<style>
.new-edit-2 .ql-toolbar {
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
.new-edit-2 .ql-editor {
  max-height: 300px;
  /* max-height: 400px; */
}
</style>
