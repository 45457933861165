<template>
  <v-card>
    <v-toolbar style="background-color: #006368; color: #fff">
      <v-toolbar-title>
        <span class="headline"
          >Medicação - Agendada: {{ getInfoAgenda() }}</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-12 text-left">
            <v-card class="px-5 py-2">
              <h5 class="text-center m-0">Medicamento</h5>
              <div class="row mt-3">
                <div class="col-6">
                  <p class="m-0">
                    Nome: {{ info.medicamento }}<br />
                    Tipo Prescrição: {{ info.compromisso.descricao_tipo }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="m-0">
                    Administração:
                    {{ info.compromisso.administracao_medicamento }}<br />
                    Dosagem: {{ info.compromisso.dosagem }}
                  </p>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <div class="container-fluid my-3">
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="data_medicacao"
                    label="Data"
                    type="date"
                    hide-details
                    :disabled="info.compromisso.status_agenda == 2"
                  />
                </div>
                <div class="col-6">
                  <v-text-field
                    v-model="hora_medicacao"
                    label="Hora"
                    hide-details
                    type="time"
                    :disabled="info.compromisso.status_agenda == 2"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <v-textarea
                    v-model="observacao_medicacao"
                    label="Observação"
                    hide-details
                    rows="3"
                    :disabled="info.compromisso.status_agenda == 2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        v-if="info.compromisso.status_agenda != 2"
        color="primary"
        :disabled="invalidForm()"
        @click="aplicarMedicamento()"
      >
        Medicar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PrescricaoService from "@/services/prescricao_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    info: Object,
  },
  components: {},
  data: () => ({
    services: {
      prescricaoService: PrescricaoService.build(),
    },
    loading: false,
    data_medicacao: "",
    hora_medicacao: "",
    observacao_medicacao: "",
  }),
  watch: {},
  mounted() {
    const comp = this.info.compromisso;
    if (comp.status == "pendente") {
      const hj = new Date();
      const hora =
        hj.getHours().toString().padStart(2, "0") +
        ":" +
        hj.getMinutes().toString().padStart(2, "0");
      this.data_medicacao = hj.toISOString().split("T")[0];
      this.hora_medicacao = hora;
    } else {
      const arrHora = comp.hora_executada.split(":");
      this.data_medicacao = comp.data_executada;
      this.hora_medicacao = `${arrHora[0]}:${arrHora[1]}`;
      this.observacao_medicacao = comp.obs_executada;
    }
  },
  computed: {},
  methods: {
    aplicarMedicamento() {
      this.loading = true;
      this.services.prescricaoService
        .postExecutarPrescricao(this.getModel())
        .then(async (response) => {
          if (response.status == 200 || response.status == 206) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Medicação executada com sucesso!",
              color: "sucess",
            });
            const resp = await response.json();
            console.log("r", resp);
            this.$emit("concluido");
            this.$emit("close");
          }
        })
        .catch((err) => {
          console.error(err);
          this.$_ACTIONS_showSnackbarMessage({
            message: err.message,
            color: "erro",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getModel() {
      return {
        id_data_agenda: this.info.compromisso.id_data_agenda,
        data_execucao: this.data_medicacao,
        hora_execucao: this.hora_medicacao + ":00",
        obs: this.observacao_medicacao,
      };
    },
    invalidForm() {
      if (
        this.data_medicacao == "" ||
        this.hora_medicacao == ""
        // this.hora_medicacao == "" ||
        // this.observacao_medicacao == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    getInfoAgenda() {
      const horaAgendada = this.info.compromisso.hora_agenda.split(":");
      return `${this.info.compromisso.data_agenda
        .split("-")
        .reverse()
        .join("/")} - ${horaAgendada[0]}:${horaAgendada[1]}`;
    },
  },
};
</script>
<style scoped>
.aplicado {
  color: green;
}
.pendente {
  color: red;
}
</style>
