const global = {
  imagem: true,
  api: {
    // Url Publico
    // baseURL: "https://api.hfaus.pettree.com.br/v1/public",
    // baseURLVetus: "",

    // Homolog
    baseURL: "https://api.hfaus.homolog.prolins.com.br/v1/public",

    // Desenvolvimento
    // baseURL:
    // Euler
    // "http://192.168.15.24/" + "anclivepa_df_dev/public",
    // "anclivepa_df_merge/public",
    // Thiago
    // "https://elastic-river-31965.pktriot.net/project_pettree_api/public",
    baseURLVetus: "",

    baseUrlChamefacil: "",
    baseUrlChamefacil_api: "",
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      // warning: "#FFCA28",
      warning: "#ae5900",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
